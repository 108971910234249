.app-container {
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



#graphBlockFilters {
  border-bottom: 1px solid var(--color-black-o15);
}

.row {
  display: flex;
  width: 100%;
  gap: 24px;
}

.leftcol {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 60%;
  flex-grow: 8;
}

.rightCol {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 40%;
  flex-grow: 4;
}

.graphleftcol {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 70%;
  flex-grow: 8;
}

.graphrightCol {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 30%;
  flex-grow: 4;
  padding-bottom: 11px;
}

.togleBtnNews {
  border-bottom: 1px solid var(--color-black-o15);
  border-top: 1px solid var(--color-black-o15);
}

@media screen and (max-width: 992px) {
  .row {
    flex-direction: column;
  }
}
